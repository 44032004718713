import React from "react";
import Filtering from "./Filtering";
import { graphql, useStaticQuery } from "gatsby";

const getData = graphql`
  query Sub6($locale: String) {
    sub: allContentfulCategories(
      filter: {
        titleCategories: { eq: "Farmasetik" }
        node_locale: { eq: $locale }
      }
    ) {
      edges {
        node {
          ref: subCategoryRef {
            title: titleSubCategory
            slug: slugSubCategory
            id: contentful_id
            productsRef {
              titleProd: titleProduct
              slugProd: slugProduct
              idProd: contentful_id
              imageProduct {
                fluid(maxWidth: 400) {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

const DataFilter = () => {
  const { sub } = useStaticQuery(getData);

  return <Filtering sub={sub} />;
};

export default DataFilter;
